<template>
	<div>
		<ul v-if="long" class="list-unstyled">
			<li class="person-line E111">
				<div class="row align-items-center mb-4">
					<div class="col-3 col-md-3 col-lg-1 mb-2 mb-md-0 filter w-100 align-self-center px-lg-0">
						<nuxt-link
							v-if="isProfessor !== null && slug"
							:to="$url.people(urlPeople, slug, language)"
						>
							<b-img-lazy
								:src="square_photo || ''"
								:alt="title"
								blank-color="lightgray"
								rounded="circle"
								class="pl-0"
								left
								block
							/>
						</nuxt-link>
						<a
							v-else-if="description"
							href="javascript:void(null);"
							@click="showModal"
						>
							<b-img-lazy
								:src="square_photo || ''"
								:alt="title"
								blank-color="lightgray"
								rounded="circle"
								class="pl-0"
								left
								block
							/>
						</a>
						<b-img-lazy
							v-else
							:src="square_photo || ''"
							:alt="title"
							blank-color="lightgray"
							rounded="circle"
							class="pl-0"
							left
							block
						/>
					</div>
					<div class="col-12 col-md-9 col-lg-10 expert bio">
						<div class="bio_top">
							<div class="font-weight-bold">
								{{ title }}
							</div>
							<div class="description">
								{{ job_title }}
							</div>
						</div>
					</div>
				</div>
			</li>
		</ul>

		<div v-else class="row align-items-center mb-4">
			<div class="col-3 col-md-3 col-lg-1 mb-2 mb-md-0 filter w-100 align-self-center px-lg-0">
				<nuxt-link
					v-if="isProfessor !== null && slug"
					:to="$url.people(urlPeople, slug, language)"
				>
					<b-img-lazy
						:src="square_photo || ''"
						:alt="title"
						blank-color="lightgray"
						rounded="circle"
						class="pl-0"
						left
						block
					/>
				</nuxt-link>
				<a
					v-else-if="description"
					href="javascript:void(null);"
					@click="showModal"
				>
					<b-img-lazy
						:src="square_photo || ''"
						:alt="title"
						blank-color="lightgray"
						rounded="circle"
						class="pl-0"
						left
						block
					/>
				</a>
				<b-img-lazy
					v-else
					:src="square_photo || ''"
					:alt="title"
					blank-color="lightgray"
					rounded="circle"
					class="pl-0"
					left
					block
				/>
			</div>
			<div class="col-12 col-md-9 col-lg-10 expert bio">
				<div class="bio_top">
					<div class="font-weight-bold">
						{{ title }}
					</div>
					<div class="description">
						{{ job_title }}
					</div>
				</div>
			</div>
		</div>

		<b-modal
			v-if="isModalOpened"
			:id="`modal-biography-${id}`"
			:title="`${title}`"
			button-size="sm"
			:centered="true"
			size="md"
			:ok-disabled="true"
			footer-class="d-none"
			:hide-header-close="false"
			:scrollable="true"
			@hidden="hideModal"
		>
			<div v-html="description" />
		</b-modal>
	</div>
</template>

<script>
const COUNT_P_FOR_SHORT_BIO = 1;

export default {
	inheritAttrs: false,
	/* eslint-disable vue/prop-name-casing */
	props: {
		id: { type: Number, required: true },
		title: { type: String, required: true },
		email: { type: String, default: null },
		phone: { type: String, default: null },
		slug: { type: String, default: '' },
		job_title: { type: String, default: '' },
		square_photo: { type: String, default: '' },
		description: { type: String, default: null },
		long: { type: Boolean, default: false },
		itemsLength: { type: Number, default: null },
		taxonomy: { type: Object, default: () => {} },
		primary_logos: { type: Array, default: () => [] },
		is_manager: { type: Number, default: 0 },
		is_professor: { type: Number, default: 0 },
		language: { type: String, default: 'ru' },
	},
	data() {
		return {
			isModalOpened: false,
		};
	},
	computed: {
		logo() {
			const logoItems = this.primary_logos.filter((i) => i.src);
			if (logoItems.length === 0) return null;
			return logoItems[0];
		},
		isProfessor() {
			if (this.is_manager) return false;
			if (this.is_professor) return true;

			return null;
		},
		urlPeople() {
			return this.isProfessor ? 'professor' : 'management';
		},
	},
	methods: {
		showModal() {
			this.isModalOpened = true;
			this.$nextTick(() => {
				this.$bvModal.show(`modal-biography-${this.id}`);
			});
		},
		hideModal() {
			this.isModalOpened = false;
		},
	},
};
</script>

<style lang="scss" scoped>
@import "/assets/styles/components/dev/lecturer";
@import "~/assets/styles/components/home/panel-person-line.scss";
@import "~/assets/styles/components/home/panel-consultant";
//@import "~/assets/styles/components/home/panel-consultants";
.person-line {
	.filter {
		@include media-breakpoint-up(lg) {
			padding-left: 0.75rem !important;
		}
	}
}

.expert {
	position: relative;
	text-align: left;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	&.bio {
		text-align: left;
		align-items: flex-start;
		justify-content: flex-start;

		@include media-breakpoint-up(sm) {
			text-align: left;
			align-items: flex-start;
			justify-content: flex-start;
		}
		@include media-breakpoint-up(lg) {
			text-align: left;
			align-items: flex-start;
			justify-content: space-between;
		}
	}
}
</style>
