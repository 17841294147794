<template>
	<div>
		<div class="experts_block">
			<new-person-line
				v-if="items.length === 1"
				:id="items[0].id"
				:title="items[0].title"
				class="experts_list"
				v-bind="items[0]"
				long
			/>
			<ul v-else class="experts_list">
				<li v-for="person in items" :key="person.id" :class="columnClasses">
					<new-person-line
						:id="person.id"
						:key="person.id"
						:title="person.title"
						v-bind="person"
						:items-length="items.length"
					/>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import NewPersonLine from './NewPersonLine.vue';

export default {
	components: {
		NewPersonLine,
	},
	props: {
		items: { type: Array, required: true },
		shortcut: { type: Boolean, default: false },
	},
	data() {
		return {
			showCount: this.items.length,
		};
	},
	computed: {
		columnClasses() {
			if (this.items.length === 2) return 'person-line';
			if (this.items.length === 3) return 'person-line';
			if (this.shortcut) return 'person-line text-left';

			return 'person-line';
		},
	},
};
</script>

<style lang="scss" scoped>
@import "~/assets/styles/components/home/panel-person-line.scss";
</style>
